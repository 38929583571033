@import 'styles.variables';

body {
  font-family: 'Roboto', sans-serif;
  font-weight: $app-font-weight-regular;
  font-size: $app-font-size;
  line-height: $app-line-height;
}

p {
  font-weight: $app-font-weight-regular;
  font-size: $app-font-size;
  line-height: $app-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  padding: 0;
  margin: 0;
}

h1,
.heading-1 {
  font-size: $app-h1-size;
  line-height: $app-h1-line-height;
  font-weight: $app-font-weight-regular;
}

h2,
.heading-2 {
  font-size: $app-h2-size;
  line-height: $app-h2-line-height;
  font-weight: $app-font-weight-regular;
}

h3,
.heading-3 {
  font-size: $app-h3-size;
  line-height: $app-h3-line-height;
  font-weight: $app-font-weight-regular;
}

h4,
.heading-4 {
  font-size: $app-h4-size;
  line-height: $app-h4-line-height;
  font-weight: $app-font-weight-regular;
}

h5,
.heading-5 {
  font-size: $app-h5-size;
  line-height: $app-h5-line-height;
  font-weight: $app-font-weight-regular;
}

h6,
.heading-6 {
  font-size: $app-h6-size;
  line-height: $app-h6-line-height;
  font-weight: $app-font-weight-regular;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  font-size: $app-font-size;
  line-height: $app-li-line-height;
}

strong {
  font-weight: $app-font-weight-bold;
}

.text-color-underemphasis {
  color: $app-text-color-underemphasis;
}

.text-color-incentive {
  color: $app-color-smartshopper;
}

.text-color-detract {
  color: $app-text-color-detract;
}

small,
.text-small {
  @extend .text-color-underemphasis;
  font-size: $app-small-font-size;
  line-height: $app-small-line-height;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.eyebrow {
  @extend .text-small;
  @extend .text-uppercase;
  color: $app-text-color-detract;
}

.text-micro {
  font-size: $app-micro-font-size;
  line-height: $app-micro-line-height;
}

.text-light {
  font-weight: $app-font-weight-light;
}

.text-regular {
  font-weight: $app-font-weight-regular;
}

.text-medium {
  font-weight: $app-font-weight-medium;
}

.text-bold {
  font-weight: $app-font-weight-bold;
}

.text-emphasis {
  @extend .text-bold;
  color: $app-text-color-emphasis;
}

.text-italic {
  font-style: italic;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-nowrap {
  white-space: nowrap;
}

.text-color-light {
  color: $app-text-color-light;
}

.text-color-dark {
  color: $app-text-color-dark;
}

.text-vertical-middle {
  vertical-align: middle;
}

.background-color-medium {
  background-color: $app-background-color-medium;
}

.background-color-dark {
  background-color: $app-background-color-dark;
}

.background-color-black {
  background-color: $app-black;
}

// Mobile
.mobile {
  h1,
  .heading-1 {
    font-size: $app-h1-mobile-size;
  }

  h5,
  .heading-5 {
    font-size: $app-h5-mobile-size;
  }
}
