@import '../styles/styles.variables';

body {
  .mat-checkbox.platform-checkbox {
    &.checkbox-light {
      .mat-checkbox-frame {
        border-color: rgba($app-white, 0.54);
      }
      .mat-checkbox-label {
        color: $app-white;
      }
      .mat-checkbox-ripple > div {
        background-color: rgba($app-white, 0.2) !important;
      }
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: $app-white;
        }
        .mat-checkbox-checkmark-path {
          stroke: $app-black !important;
        }
      }
    }
  }
}

.no-outline {
  outline: none;
}
