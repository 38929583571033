@use 'styles.variables' as vars;
@use '@angular/material' as mat;

// Repeated classes for higher specificity to prevent angular-material-css-vars from overriding.
body .high-contrast-theme.high-contrast-theme.high-contrast-theme {
  color: rgba(var(--palette-primary-contrast-500)) !important;
  $high-contrast-palette: mat.define-palette(mat.$grey-palette, 50);
  $high-contrast-theme: mat.define-dark-theme(
    (
      color: (
        primary: $high-contrast-palette,
        accent: $high-contrast-palette,
      ),
    )
  );
  @include mat.all-component-colors($high-contrast-theme);
  @include mat.checkbox-density(-3);
  @include mat.radio-density(-3);

  // Override high-contrast fields with inherited colors.
  // TODO: This needs to be handled by the DLS theming.
  .mat-select-placeholder,
  .mat-select-value,
  .mat-select-arrow,
  .platform-button,
  a {
    color: inherit !important;
  }
  .mat-form-field-label {
    color: inherit !important;
    opacity: 0.7;
  }
  .mat-form-field-underline::before,
  .mat-form-field-ripple {
    background-color: currentColor !important;
  }
  .mat-select.mat-select-disabled {
    .mat-select-placeholder,
    .mat-select-value,
    .mat-select-arrow {
      opacity: 0.7;
    }
  }
  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: rgba(var(--palette-primary-contrast-500)) !important;
    }
    .mat-checkbox-checkmark-path {
      stroke: rgb(var(--palette-primary-500)) !important;
    }
  }
  .mat-checkbox-frame {
    border-color: currentColor !important;
  }
  .zelis-dls button.zelis-button.dark-bg.mat-stroked-button.mat-primary {
    color: inherit !important;
    border-color: currentColor !important;
  }
}

// Primary Color
.primary-color-background {
  fill: rgb(var(--palette-primary-500));
  background-color: rgb(var(--palette-primary-500));
}

.primary-color-background-text {
  color: rgba(var(--palette-primary-contrast-500));
}

.primary-color-text {
  color: rgb(var(--palette-primary-500));
}

.primary-color {
  @extend .primary-color-background;
  @extend .primary-color-background-text;
}

// Accent Color
.accent-color-background {
  fill: rgb(var(--palette-accent-500));
  background-color: rgb(var(--palette-accent-500));
}

.accent-color-background-text {
  color: rgba(var(--palette-accent-contrast-500));
}

.accent-color-text {
  color: rgb(var(--palette-accent-500));
}

.accent-color {
  @extend .accent-color-background;
  @extend .accent-color-background-text;
}

// Warn Color
.warn-color-background {
  fill: rgb(var(--palette-m3-system-red-40));
  background-color: rgb(var(--palette-m3-system-red-40));
}

.warn-color-background-text {
  color: rgba(var(--palette-m3-system-red-contrast-40));
}

.warn-color-text {
  color: rgb(var(--palette-m3-system-red-40));
}

.warn-color {
  @extend .warn-color-background;
  @extend .warn-color-background-text;
}
