@import 'styles.variables';

.pin {
  position: relative;
  color: $sos-tooltip-blue;

  .number {
    font-size: 1.2em;
    color: $app-white;
    font-weight: bold;
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 3;
  }

  // Covers the hole in the "place" icon
  .circle {
    font-size: 1em;
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
    z-index: 2;
    width: 100%;
    text-align: center;
  }

  .icon {
    font-size: 34px;
    width: 34px;
    height: 34px;
  }
}

.screen-reader-only {
  position: absolute;
  left: -999em;
}

.main-profile-container {
  margin-left: 2px;
  overflow-y: hidden;
}

.profile-sidenav-container {
  margin-right: 16px;
}

.profile-spacer {
  margin: 4px 0px;
}

platform-prs {
  .mat-form-field-type-mat-select {
    border-bottom: 1px solid $mat-form-border;
  }
}

platform-timeline {
  .timeline {
    width: 100%;
  }
}

.profile-sidenav-container {
  top: -10px;
  height: 100%;
  transform: none;
  overflow: visible;
  margin-bottom: 200px;

  .primary-color-light {
    background-color: rgb(var(--palette-accent-50));
  }

  .profile-sidenav {
    margin-bottom: 24px;
    box-shadow: 0 2px 40px 0 rgba($app-black, 0.15);
    background-color: $app-white;

    .primary-color-text {
      color: $app-black;
    }

    &:not(.mobile){
      .mat-nav-list {
        padding: 0px !important;
        :last-child {
          border: 0 !important;
        }
      }
    }

    .mat-list-item {
      border-bottom: 2px solid $divider-gray;
    }

    .mat-nav-list .mat-list-item:hover {
      background-color: rgb(var(--palette-accent-50));
    }

    .mat-list .mat-list-item:focus,
    .mat-nav-list .mat-list-item:focus {
      outline: none;
    }

    .sidenav-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      padding: 16px 0px;
    }
  }

  .mat-drawer {
    overflow-y: visible !important;
  }
}

// Mobile
.mobile {
  .profile-sidenav-container {
    margin-right: 0px;
  }

 // PRS mobile
 platform-prs .mat-checkbox-label {
    font-size: 11px;
 }
}

// Print
@media print {
  html,
  body,
  h1,
  .page-container,
  .platform-page-container,
  .main-profile-container > section > main,
  .platform-profile-card,
  .platform-expandable-section,
  .section-title,
  .mat-drawer-container,
  .mat-card,
  .mat-card-content,
  .mat-card-header,
  .app-wrapper,
  .flex-content {
    max-height: 100% !important;
    display: block !important;
    flex: none !important;
    float: none !important;
    overflow: visible !important;
    margin: 0cm !important;
    padding: 0cm !important;
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
    page-break-after: avoid !important;
    position: static !important;
  }
  *[fxlayout],
  *[fxlayout] > * {
    height: auto !important;
    margin: 0cm !important;
    padding: 0cm !important;
  }
  header,
  footer,
  .profile-sidenav-container,
  .share,
  .mat-progress-bar,
  .autosuggest-container {
    display: none !important;
  }
}
