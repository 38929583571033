@import 'styles.pui-comps.mixins';
@import 'styles.variables';
@import 'styles.extenders';

html,
body {
  height: 100% !important;
  padding: 0px !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  background-color: $divider-gray;

  :active {
    outline: none !important;
  }

  ul {
    list-style-type: none;
  }
}

.no-wrap {
  white-space: nowrap;
}

.no-outline {
  outline: none;
  box-shadow: none;
}

.no-decoration {
  text-decoration: none !important;
  text-decoration-line: none;
}

.no-scroll {
  overflow: hidden;
}

.right {
  float: right;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  color: $gray-on-gray;
}

.text-error {
  color: $alert-red;
}

.text-white {
  color: $app-white;
}

.no-pointer-events {
  pointer-events: none;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mat-tooltip {
  font-size: 12px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.mat-dialog-container {
  border-radius: 8px !important;
}

.tooltip-icon {
  color: rgb(var(--palette-accent-500));
  font-size: 16px;
  vertical-align: middle;
}

/* Styles for app */

.btn {
  background-color: rgb(var(--palette-accent-500)) !important;
  color: rgba(var(--palette-accent-contrast-500)) !important;
}

.menu-bar-bg {
  width: 134px;
  height: 40px;
  padding: 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  border: solid 1px rgb(var(--palette-accent-500));
  color: rgb(var(--palette-accent-500));
}

.heading-break {
  width: 100%;
  height: 2px;
  background-color: $divider-gray;
  margin-bottom: 8px;
  margin-top: 8px;
}

.pdf {
  padding: 5px 0px 5px 10px;
}

.unread-dot {
  height: 8px;
  width: 8px;
  background-color: rgb(var(--palette-accent-500));
  border-radius: 50%;
  display: inline-block;
  opacity: 0.3;
}

.trademark {
  vertical-align: super;
  font-size: 0.3em;
  font-weight: $app-font-weight-light;
  @include padding($left: 0.5);
}

.trademark-none .trademark {
  display: none;
}

.vert-middle {
  vertical-align: middle;
}

.spinner {
  margin: 0 auto;
}

.search-box {
  margin: 0 2px;
  padding: 0 4px;
  height: 56px;
}

.primary-color {
  background-color: rgb(var(--palette-primary-500)) !important;
}

.primary-color-50 {
  background-color: rgb(var(--palette-primary-50)) !important;
}

.primary-color-100 {
  background-color: rgb(var(--palette-primary-100)) !important;
}

.primary-color-light {
  background-color: var(--primary-color-light-lighten);
}

.secondary-color-text {
  color: rgba(var(--palette-accent-500)) !important;
}

.green-text {
  color: $green-text;
}

.link-blue {
  color: $link-blue !important;
}

.gray-900 {
  color: $gray-900;
}

.inner-link-blue {
  a {
    color: $link-blue !important;
  }
}

.primary-color-hover {
  &:hover {
    @extend .primary-color-light;
  }
}

[hidden] {
  display: none !important;
}

.stop-scrolling {
  height: 100% !important;
  overflow: hidden !important;
}

mat-option {
  color: $app-black !important;
}

.border-radius-4 {
  border-radius: 4px;
}

.search-overlay {
  mat-dialog-container {
    background: $background-gray;
  }
}

.rts-welcome-overlay {
  mat-dialog-container {
    padding: 0;
  }
}

.zelis-dls {
  .search-overlay {
    mat-dialog-container {
      background: $background-gray;
      max-height: none;
    }
  }

  .search-overlay-backdrop {
    background: $background-gray;
    &.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 0.8;
    }
  }
}

.provider-referral-overlay {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.search-overlay-backdrop {
  background: $background-gray;
}

.serp-cost-column {
  font-size: 24px;
  font-weight: 500;
  color: $incentives-green;
}

// Provider image styling
.provider-picture-container {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: solid 1px $divider-gray;
  background: $background-gray;
  overflow: hidden;

  img {
    display: inline-block;
    height: auto;
    width: 100%;
    vertical-align: middle;
    position: relative;
  }

  .fallback {
    opacity: 0.4;
    padding: 4px;
    height: 100%;
    width: 100%;
    top: 0px;
  }
}

.opacity-filter-20 {
  background-color: rgba($app-black, 0.2);
}

.kill-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
}

.fullscreen-overlay {
  height: 100% !important;
  max-width: 100vw !important;
  width: 100vw;

  .mat-dialog-container {
    padding: 0px !important;
  }
}

.location-fullscreen-overlay {
  .cdk-overlay-pane {
    left: 0px !important;
    width: 100vw !important;
    margin-top: 16px;
  }

  .mat-autocomplete-panel {
    box-shadow: none !important;
    border-radius: 0;
    padding-top: 0;
    max-height: 100% !important;
  }

  .mat-form-field-infix {
    .mat-form-field-label {
      position: absolute;
      padding-top: 8px;
      color: rgba(var(--palette-primary-contrast-500)) !important;
    }
  }
}

.max-full {
  max-width: 100%;
}

.full-width {
  width: 100%;
}

.width-20 {
  width: 20%;
}

.width-80 {
  width: 80%;
}

.vw-width {
  width: 100vw;

  &.center {
    left: calc(-50vw + 50%);
    position: relative;
    box-sizing: border-box;
  }
}

// Material CDK Overlay Overrides
.global-header-autocomplete.mobile-overlay {
  height: calc(100vh - 112px) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.global-header-autocomplete {
  width: 57vw !important;
  &.mobile-overlay {
    width: 100vw !important;
  }
}

.global-language-dropdown {
  @include margin($top: 5);
  padding: 8px 0 !important;
}

.eoc-intended-provider-overlay {
  mat-dialog-container {
    background-color: $gray-200 !important;
  }
}

.mobile-network-overlay {
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 100vh !important;
  min-width: -webkit-fill-available !important;
  max-height: 85% !important;
  @include padding($top: 3, $important: true);
}

.mobile-network-cdk {
  .cdk-overlay-pane {
    margin-top: 60px !important;
    width: 100% !important;
    transform: none !important;
  }

  .cdk-overlay-backdrop ~ .cdk-overlay-backdrop {
    display: none;
  }
}

.disable-network-dropdown {
  display: none;
}

// Mobile styles
.mobile-overlay {
  .autocomplete-option,
  .autocomplete-providers {
    padding-left: 16px !important;
    line-height: 32px !important;
  }

  .category-show-more-search_specialties,
  .category-show-more-procedures,
  .category-show-more {
    margin-left: 16px !important;
  }
}

.mobile-overlay.mat-autocomplete-panel {
  max-height: 100vh !important;
  position: absolute;
  top: 105px;
}

.mobile-filter-pane {
  min-width: inherit !important;
  padding-left: 1em;
}

.desktop-filter-pane {
  max-width: 500px !important;
}

.desktop-network-dropdown-pane {
  @include margin($top: 6);
  padding: 12px 0 !important;
}

.fullscreen-overlay-autosuggest ~ .cdk-overlay-pane {
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
  top: 88px !important;
}

.fullscreen-overlay {
  overflow: hidden;

  .cdk-overlay-pane {
    top: 0px !important;
    left: 0px !important;
  }
}

.sort-select-dropdown {
  max-height: 550px !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.8;
}

.pages {
  ul, ol {
    margin: 14px 0;
    padding-left: 40px;
    list-style-type: disc;
    
    li {
      line-height: 24px;
    }
  }
  ol {
    list-style-type: decimal;
  }
}

@media screen and (max-width: 960px) {
  select,
  textarea,
  input {
    font-size: 14px !important;
  }
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: none;
  }
}

.no-halo,
.mobile .no-mobile-halo {
  outline: none !important;
}

.no-halo:focus,
.mobile .no-mobile-halo:focus {
  outline: none !important;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.center {
  text-align: center;
}

a[href^='tel:'] {
  white-space: nowrap;
}

.white-bg {
  background-color: $app-white;
}

.padding-bottom-1 {
  @include padding($bottom: 1);
}

.strong {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.display-block {
  display: block;
}

.margin-top-none {
  @include margin($top: 0);
}

.margin-bottom-none {
  @include margin($bottom: 0);
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.fs-16px {
  font-size: 16px;
}

.fs-18px {
  font-size: 18px;
}

.lh-16px {
  line-height: 16px;
}

.fs-2rem {
  font-size: 2rem;
}

.lh-24px {
  line-height: 24px;
}

.divider-top-gray {
  border-top: 2px solid $divider-gray;
}

hr {
  border: none;
  background-color: $divider-gray;
  color: $divider-gray;
  height: 2px;
}

.read-only {
  pointer-events: none;
}

.inline-block {
  display: inline-block;
}

.external-link-indicator {
  a[href^='http']:not(.external-link-indicator):not(.no-external-link-indicator):not(.header-login-button):not(.login):not(.attribution):not(.award-container):not(.award-icon):not(.certified-link):after,
  .award-container span,
  .certified-link .certified-text {
    @extend .external-link-indicator-icon;
  }

  // TODO: Not needed after discovering where the hell the .external-link-indicator override is coming from.
  a.no-external-link-indicator:after {
    display: none !important;
  }
}

// TODO: Finalize reusable outline FAB
button.platform-button.button-outline {
  border: 1px $app-black solid;
  width: 48px;
  height: 48px;

  .mat-button-wrapper {
    vertical-align: middle;
    padding: 0 0;
    color: $app-black;
    font-size: 22px;
    line-height: 48px;
  }
}

// Print
@media print {
  img {
    display: none;
  }

  div {
    page-break-inside: avoid;
  }

  .printable .mat-dialog-container {
    box-shadow: none !important;
    outline: none;
  }

  .cdk-overlay-container {
    snack-bar-container {
      display: none;
    }
  }
}

// Full-Screen Bottom Sheet
.full-screen-sheet {
  .mat-bottom-sheet-container {
    &.mat-bottom-sheet-container-large,
    &.mat-bottom-sheet-container {
      min-width: auto;
      max-width: none;
      max-height: none;
      width: 100vw;
      height: 100vh;
      padding: 0 0;
      overflow-x: hidden;
    }
  }
}

// Full-Screen Dialog
.cdk-overlay-container:not(.zelis-dls) {
  .cdk-overlay-pane {
    &.full-screen {
      .mat-dialog-container {
        min-width: auto;
        max-width: none;
        max-height: none;
        width: 100vw;
        height: 100vh;
        @include padding($all: 3, $important: true);
        overflow-x: hidden;
        position: fixed;
        top: 0;
        left: 0;
        border-radius: 0 !important;
      }
    }
  }
}

.title-separator {
  @include margin($top: 2);
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    height: 4px;
    width: 40px;
    background-color: $divider-gray;
  }
}

body .menu-standard {
  min-width: 600px;
}

body.mobile {
  .menu-standard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-width: auto;
    max-width: 100vw;
    max-height: 100vh;
  }
}

.cancel-top-margin-8 {
  @include margin($top: -1);
}

.pill-secondary {
  background: linear-gradient(
      0deg,
      rgba($app-white, 0.9),
      rgba($app-white, 0.9)
    ),
    rgb(var(--palette-accent-500));
  color: rgb(var(--palette-primary-500));
  font-size: 12px;
}

.list-style-inside {
  list-style-type: disc;
  list-style-position: inside;
}

// Some disclaimers are configured with bullet lists that need normalization.
.disclaimers {
  ul li {
    line-height: 24px;
  }
}

.remove-underline {
  mat-form-field:not(.mat-focused) .mat-form-field-underline {
    display: none;
  }
}

.preferred-provider-dialog {
  .mat-dialog-container {
    overflow-y: hidden;
  }
}

@media screen and (min-height: 720px) {
  .claim-overlay-dialog {
    &.full-screen {
      .mat-dialog-container {
        .mat-dialog-content {
          overflow-y: hidden;
        }
      }
    }
  }
}

//"View More/Less" button styles
.view-button {
  width: 100%;
  background: none !important;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  display: flex;  
  align-items: center;
  justify-content: center;
  height: inherit;

  & span {
    margin-right: auto;
  }
  & mat-icon {
    margin-left: auto;
  }
}
