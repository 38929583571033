@import '_styles.tailwind.scss';
@import '_styles.variables.scss';
@import '_styles.material.theme.scss';
@import '_styles.material-css-vars.scss';
@import '_styles.fonts.scss';
@import '_styles.animations.scss';
@import '_styles.base.scss';
@import '_styles.components.scss';
@import '_styles.loaders.scss';
@import '_styles.pui-comps.global.scss';
@import '_styles.material-overrides.scss';

:root {
  --disclaimer-color: #{$disclaimer-color};
  --header-text-color: #{$header-text-color};

  --cost-compare-lighten: rgb(var(--palette-primary-50));
  --primary-color-light-lighten: rgb(var(--palette-primary-50));
}
