@import 'styles.pui-comps.mixins';
@import 'styles.variables';

.material-override {
  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      background-color: $app-white;
      border-radius: 4px;
    }
  }

  &.mat-checkbox {
    .mat-checkbox-label {
      white-space: normal;
    }
  }

  &.mat-form-field {
    &.full-width {
      width: 100%;

      .mat-form-field-infix {
        width: 100%;
      }
    }

    &.no-padding {
      margin-bottom: -1.34375em;
    }

    &.no-input-padding {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      &.mat-form-field-appearance-outline {
        &:not(.mat-form-field-has-label) {
          .mat-input-element {
            transform: translateY(-3px);
          }
        }
      }
    }

    &.no-arrow {
      .mat-select-arrow-wrapper {
        visibility: hidden;
      }
    }

    &.no-label {
      &:not(.mat-form-field-has-label) .mat-form-field-infix {
        border-top-width: 0;
      }
    }

    &.mdx-fill {
      .mat-form-field-flex {
        border-radius: 0;
        line-height: 48px;
        @include padding($x: 1, $y: 0);
      }

      .mat-form-field-infix,
      .mat-form-field-wrapper {
        @include padding($all: 0);
      }

      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }

      .mat-form-field-underline,
      .mat-form-field-subscript-wrapper {
        display: none;
      }

      .mat-select:not(.mat-select-disabled) {
        .mat-select-placeholder {
          color: rgba(var(--palette-primary-contrast-500));
        }
      }
    }
  }

  &.mat-menu-item {
    &.auto-height {
      height: auto;
    }
  }

  &.compare-checkbox {
    &.mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container .mat-ripple-element {
      transform: none;
    }
  }

}

.material-menu-min-width {
  .mat-menu-content {
    min-width: 232px;
  }
}

// Repeated classes for higher specificity to prevent angular-material-css-vars from overriding.
.high-contrast-theme.high-contrast-theme.high-contrast-theme {
  &.network-disabled {
    .mat-select-disabled {
      .mat-select-value-text {
        color: rgba(var(--palette-primary-contrast-500));
      }
    }

    .mat-form-field {
      .mat-select-disabled {
        .mat-select-arrow {
          color: rgba(var(--palette-primary-contrast-500));
        }
      }
    }

    .mat-form-field-appearance-standard.mat-form-field-disabled,
      .mat-form-field-underline {
      background: $app-white;
    }
  }

  .material-override,
  &.material-override {
    &.mat-form-field {
      &.mdx-fill {
        .mat-form-field-flex {
          background-color: rgba($color: $app-white, $alpha: 0.2);
        }

        &.value-selected {
          .mat-form-field-flex {
            background-color: rgba($color: $app-white, $alpha: 0.4);
          }
        }

        &.mat-form-field-disabled {
          .mat-form-field-flex {
            background-color: rgba($color: $app-white, $alpha: 0.1);
          }
        }
      }
    }
  }
}

.mat-menu-content,
.mat-select-panel,
.mat-autocomplete-panel {
  &:not(.default-material) {
    &.mat-autocomplete-panel {
      &.global-header-autocomplete {
        .mat-option {
          border-bottom: none;
        }
      }

      .option-heading {
        color: $link-blue;
        font-size: 14px;
        font-weight: $app-font-weight-medium;
      }

      .option-content {
        font-size: 12px;
        font-style: italic;
        font-weight: $app-font-weight-regular;
        color: $medium-gray;
      }
    }
    .mat-menu-item,
    .mat-option {
      white-space: normal;
      height: auto;
      font-size: 14px;
      line-height: 21px;
      @include padding($y: 2, $right: 4, $left: 2);
      border-bottom: 1px rgba($app-black, 0.04) solid;

      &:last-of-type {
        border-bottom: 0;
      }

      .selected-checkmark {
        @include margin($right: -3);
      }

      .mat-option-text {
        overflow: visible;
      }
    }
  }
}

// TODO: Remove once added to pui-comps styleguide
.platform-button {
  &.button-full {
    .mat-button-wrapper {
      display: block;
    }
  }

  &.mat-raised-button {
    &.video-button {
      &.button-basic {
        color: rgb(var(--palette-accent-500));
      }
    }
  }
}

.platform-expandable-section {
  &.accordion,
  &.direction-up {
    button {
      color: $link-blue;
    }
  }
}

.top-right-arrow {
  .mat-expansion-indicator {
    position: absolute;
    top: 8px;
    right: 0;
  }
}

.mat-tooltip {
  background: $gray-on-white;
}

// TODO: Track down actual cause of wacky styling of mobile profile-provider-affiliations component's medical group dropdown.
body.mobile .mat-select-panel.medical-group-dropdown-overlay {
  @include margin($top: 3.5, $important: true);
}

// TODO: Track down actual cause of wacky styling of mobile profile-provider-affiliations component's medical group dropdown.
.mat-select-panel.medical-group-dropdown-overlay {
  @include margin($top: 3.5, $important: true);
}

// TODO: Addresses scroll bar functionality for Windows OS on Chrome
.cdk-overlay-pane.mat-select-panel-wrap {
  overflow-y: visible;
}

.global-header-dropdown {
  min-height: max-content !important;
  .mat-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
